import React, { ReactElement } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { LayoutProps } from './layout.model';
import Footer from '../../components/footer';
// import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// import InFeed from '../../components/adsense/in-feed';
import { CookieBanner } from '@palmabit/react-cookie-law';
import { GTAG_OPTIN_KEY } from 'gatsby-plugin-google-gtag-optin'; // Or use your own if you changed it in the config
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Layout: React.FC<LayoutProps> = (props): ReactElement => {
  const { title, children } = props;
  const [toggleNav, setToggleNav] = React.useState(false);
  const { t } = useTranslation();
  const consent = () => {
    localStorage.setItem(GTAG_OPTIN_KEY, 'true');
    if (typeof window !== 'undefined' && window.localStorage) {
      if (typeof window.loadGtag == 'function') {
        window.loadGtag();
      }
    }
  };
  return (
    <div className={`wrapper-main ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a className="nav-burger" href={`#`} aria-label="abrir/cerrar menú" onClick={() => setToggleNav(!toggleNav)}>
            <div className="hamburger hamburger--collapse" aria-label="Menu" role="button" aria-controls="swup">
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className={`site-head-left ${!toggleNav ? `closed` : ``}`}>
            <ul className="nav" role="menu">
              <li className="nav-link" role="menuitem">
                <AniLink
                  fade
                  duration={1.2}
                  top="entry"
                  to={`/`}
                  activeClassName="nav-dot-current"
                  partiallyActive={false}
                >
                  Inicio
                  <span className="nav-dot"></span>
                </AniLink>
              </li>
              <li className="nav-link" role="menuitem">
                <AniLink
                  fade
                  duration={1.2}
                  top="entry"
                  to={`/recetas`}
                  activeClassName="nav-dot-current"
                  partiallyActive={true}
                >
                  Recetas
                  <span className="nav-dot"></span>
                </AniLink>
              </li>
              <li className="nav-link" role="menuitem">
                <AniLink
                  fade
                  duration={1.2}
                  top="entry"
                  to={`/chefs`}
                  activeClassName="nav-dot-current"
                  partiallyActive={true}
                >
                  Chefs
                  <span className="nav-dot"></span>
                </AniLink>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <AniLink fade duration={1.2} top="entry" className="site-head-logo" to={`/`}>
              <img src="/logo.svg" alt="s!chef. logo"></img>
            </AniLink>
          </div>
          <div>
            <div className="social-links">
              {/* <a href="https://www.facebook.com" title="Facebook" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
              <a href="https://twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer">
                Twitter
              </a> */}
              <AniLink
                className="search-icon"
                swipe
                direction="left"
                duration={0.4}
                top="entry"
                to={`/search`}
                title="search"
                state={{
                  modal: true,
                }}
              >
                <FontAwesomeIcon icon={faSearch} size="1x" />
              </AniLink>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div className="transition-fade">{children}</div>
      </main>
      {/* <InFeed /> */}
      <Footer title={title} />
      <CookieBanner
        message={t('COOKIES_TITLE')}
        policyLink="/cookies"
        privacyPolicyLinkText={t('COOKIES_LINK')}
        wholeDomain={true}
        className="cookies-container"
        necessaryOptionText={t('COOKIES_necessaryOptionText')}
        preferencesOptionText={t('COOKIES_preferencesOptionText')}
        statisticsOptionText={t('COOKIES_statisticsOptionText')}
        marketingOptionText={t('COOKIES_marketingOptionText')}
        showMarketingOption={true}
        state={{ preferencesCookies: true, statisticsCookies: true, marketingCookies: true }}
        styles={{
          button: {
            backgroundColor: 'var(--theme-color-iceBlue)',
            color: '#ffffff',
            class: '.cookies-button',
          },
          checkbox: {
            backgroundColor: 'var(--theme-color-iceBlue)',
          },
          dialog: {
            background: '#f7f7f7',
            position: 'fixed',
            alignItems: 'baseline',
            fontSize: '13px',
            left: '0px',
            right: '0px',
            // top: '0px',
            bottom: '0px',
            zIndex: '100000',
            padding: '10px',
          },
        }}
        // onAccept={() => {}}
        acceptButtonText={t('COOKIES_ACCEPT_BUTTON')}
        declineButtonText={t('COOKIES_DECLINE_BUTTON')}
        // onAcceptPreferences={() => {}}
        onAcceptStatistics={() => {
          consent();
        }}
        // onAcceptMarketing={() => {}}
      />
      {/* <CookieConsent
        location="bottom"
        onDecline={() => {
          window['ga-disable-G-JN93V5YBRC'] = true;
        }}
        buttonText={t('COOKIES_ACCEPT_BUTTON')}
        declineButtonText={t('COOKIES_DECLINE_BUTTON')}
        cookieName="gatsby-gdpr-google-analytics"
        containerClasses="cookies-container"
        contentClasses="cookies-content"
        buttonClasses="cookies-button accept"
        declineButtonClasses="cookies-button decline"
        enableDeclineButton
        disableStyles={true}
      >
        {t('COOKIES_TITLE')}
        <AniLink fade duration={1.2} top="entry" to={`/cookies`}>
          {t('COOKIES_LINK')}
        </AniLink>
        .
      </CookieConsent> */}
    </div>
  );
};

export default Layout;
