import React, { ReactElement } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FooterProps } from './footer.model';
import FooterStyles from './footer.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Footer: React.FC<FooterProps> = (props): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={FooterStyles.section_content_wrap}>
      <div className={`${FooterStyles.section_subscribe} ${FooterStyles.wrap}`}>
        <div className={FooterStyles.subscribe_wrap}>
          <div className={FooterStyles.ityped}>
            <span className={FooterStyles.ityped_wrap}>{t('FOOTER_SLOGAN_TITLE')}</span>
          </div>
        </div>
      </div>
      <footer className={FooterStyles.section_footer}>
        <div className={`${FooterStyles.footer_wrap} ${FooterStyles.wrap} ${FooterStyles.flex}`}>
          <div className={`${FooterStyles.footer_logo} ${FooterStyles.flex}`}>
            <AniLink fade duration={1.2} className={FooterStyles.is_logo} to={`/`}>
              <img src="/logo.svg" alt="Sichef. logo"></img>
            </AniLink>
          </div>
          <div className={FooterStyles.footer_nav}>
            <ul className={FooterStyles.nav_list}>
              <li className={FooterStyles.nav_list_item}>
                <AniLink fade duration={1.2} className={FooterStyles.nav_link} to={`/creditos`}>
                  {t('MENU_ABOUT_TITLE')}
                </AniLink>
                <span className={FooterStyles.nav_dot}></span>
              </li>
              <li className={FooterStyles.nav_list_item}>
                <AniLink fade duration={1.2} className={FooterStyles.nav_link} to={`/aviso-legal`}>
                  {t('MENU_LEGAL_ADVICE_TITLE')}
                </AniLink>
                <span className={FooterStyles.nav_dot}></span>
              </li>
              <li className={FooterStyles.nav_list_item}>
                <AniLink fade duration={1.2} className={FooterStyles.nav_link} to={`/cookies`}>
                  {t('MENU_COOKIES_TITLE')}
                </AniLink>
                <span className={FooterStyles.nav_dot}></span>
              </li>
            </ul>
          </div>
          <div className={`${FooterStyles.footer_social_links} ${FooterStyles.flex}`}>
            <a className={FooterStyles.nav_link} href="mailto:pulsityapp@gmail.com" title="contacta con nosotros">
              <FontAwesomeIcon className={FooterStyles.global_svg} icon={faEnvelope} size="2x" />
            </a>
          </div>
        </div>
        <div className={FooterStyles.footer_copyright}>
          © {new Date().getFullYear()}{' '}
          <AniLink fade duration={1.2} to={`/`}>
            {props.title}
          </AniLink>{' '}
          {t('FOOTER_RIGHTS')}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
