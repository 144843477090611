/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactElement } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { SeoProps } from './seo.model';

/**
 * Gatsby Component for SEO.
 *
 * Substring is used beacause of Open Graph for social sharing limitations
 * @see https://stackoverflow.com/questions/19778620/provide-an-image-for-whatsapp-link-sharing/61078968#61078968
 */
const SEO: React.FC<SeoProps> = ({
  description = '',
  lang = 'es',
  meta = [],
  keywords = [],
  title,
  image,
  url,
}): ReactElement => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            siteUrl
          }
        }
      }
    `,
  );

  const metaDescription: string = (description || site.siteMetadata.description).replace(/(\r\n|\n|\r)/gm, '');
  const pageUrl = url || site.siteMetadata.siteUrl;
  const imageURL = image ? site.siteMetadata.siteUrl + image : site.siteMetadata.image;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title.substr(0, 250)}
      titleTemplate={`%s | ${site.siteMetadata.title} ${site.siteMetadata.description}`}
      meta={[
        {
          name: `description`,
          content: metaDescription.substr(0, 250),
        },
        {
          property: `og:title`,
          content: title.substr(0, 250),
        },
        {
          property: `og:url`,
          content: pageUrl,
        },
        {
          property: `og:description`,
          content: metaDescription.substr(0, 250),
        },
        {
          property: `og:image`,
          content: imageURL,
          itemprop: `image`,
        },
        {
          property: `og:image:secure_url`,
          content: imageURL,
          itemprop: `image`,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:locale`,
          content: `es_ES`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: imageURL,
        },
        {
          property: `image`,
          content: imageURL,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : [],
        )
        .concat(meta)}
    />
  );
};

export default SEO;
